import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Table as ChakraTable,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner as SpinnerChakra,
  Switch,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useOutsideClick,
} from '@chakra-ui/react';
import { toZonedTime } from 'date-fns-tz';
import { Field, FieldArray, Formik, getIn } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import { Alert, Button, Col, Form, Spinner, Table } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Collapse from 'react-bootstrap/Collapse';
import { AiFillCheckCircle } from 'react-icons/ai';
import { RiCloseCircleFill, RiErrorWarningFill } from 'react-icons/ri';
import { Prompt } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { get, getTokenAndEmailFromSession, patch, post } from '../../../common/api-utils';
import { getMinuteDifferenceBetweenTimeStamps } from '../../../common/date-formatting';
import useInterval from '../../../common/hooks/use-interval';
import { debounceEvent, displayAPIErrorMessage, formatDateTime } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import { MultiToggleSwitch } from '../../../components/MultiToggleSwitch';
import MeterCommsStatus, { CellMode } from '../../../components/SignalQuality';
import { PolarityNormalIcon, PolarityReverseIcon } from '../../../styles/custom-icons';
import { FORMATTED_TIMEZONES } from '../../../utils/timezone';
import MeterConfigurationChartContainer from './MeterConfigurationChartContainer';

const INITIAL_STATE = {
  isLoaded: false,
  isEditing: false,
  meterData: null,
  serialNumber: null,
  isUninitialised: false,
  logData: null,
  isLoading: false,
  firstTimestamp: null,
  siteId: null,
  siteCircuits: [],
};

export const DEFAULT_TIMEZONE = 'Australia/Sydney';

const columns = [
  {
    dataField: 'device_id',
    text: 'Device ID',
  },
  {
    dataField: 'timestamp',
    text: 'Time',
  },
  {
    dataField: 'channel',
    text: 'Channel',
  },
  {
    dataField: 'attribute_changed',
    text: 'Attribute Changed',
  },
  {
    dataField: 'original',
    text: 'Original',
  },
  {
    dataField: 'changed_to',
    text: 'Changed To',
  },
  {
    dataField: 'derms_user',
    text: 'User',
  },
];

/**
 * Main page for the Wattwatchers channel configuration tool.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MeterConfiguration(props) {
  const id = props.match.params?.id;
  const [state, setState] = useState(INITIAL_STATE);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // Meters beginning with DD typically denote 4g capability
  const is4gMeter = id?.substring(0, 2) === 'DD';
  const cellMode: CellMode = is4gMeter ? '4G' : '3G';
  useEffect(() => {
    async function fetchAPI() {
      try {
        const { jwtToken: token } = await getTokenAndEmailFromSession();
        const meterData = await get('ww_meter_data', `/device/wattwatchers/devices/${id}`, token);
        let timezone = meterData.timezone;
        if (!timezone) {
          timezone = DEFAULT_TIMEZONE;
          toast.warn('WARNING: No timezone is set for this meter - defaulted to Sydney/Australia.');
        }

        const firstTimestamp = await get(
          'ww_meter_data',
          `/device/wattwatchers/devices/${id}/first?timezone=${timezone}`,
          token
        );

        const [{ data }, meter] = await Promise.all([
          get('ww_logs', `/device/wattwatchers/devices/${id}/logs`, token),
          get('devices', `/device/wattwatchers/devices/${id}`, token),
        ]);

        const siteCircuits = [];

        const siteId = meter.length ? meter[0].clipsal_solar_id : null;

        if (siteId) {
          const circuits = await get('circuits', `/site/sites/${siteId}/circuits`, token);
          siteCircuits.push(...circuits);
        }

        setState({
          ...state,
          isLoaded: true,
          isEditing: true,
          isUninitialised: meterData.uninitialised,
          meterData: {
            ...meterData,
            timezone,
          },
          serialNumber: id,
          firstTimestamp: firstTimestamp,
          logData: data,
          siteId,
          siteCircuits,
        });
      } catch (e) {
        displayAPIErrorMessage(e);

        setState({
          ...state,
          isLoaded: true,
          serialNumber: id,
        });
      }
    }

    // Only fetch if we haven't loaded, and there is an ID in params
    if (!state.isLoaded) {
      if (id) fetchAPI();
      else {
        setState({
          ...state,
          isLoaded: true,
        });
      }
    }
  }, [id, state]);

  async function handlePopulateForm(meterData, serialNumber, siteId = null, siteCircuits = [], logData) {
    props.history.push(`/wattwatchers_meter_configuration/${serialNumber}`);
    let timezone = meterData.timezone;

    if (!timezone) {
      timezone = DEFAULT_TIMEZONE;
      toast.warn('WARNING: No timezone is set for this meter - defaulted to Sydney/Australia.');
    }

    setState({
      ...state,
      isEditing: true,
      serialNumber,
      meterData: {
        ...meterData,
        timezone,
      },
      isUninitialised: meterData.uninitialised,
      siteId,
      siteCircuits,
      logData,
    });
  }

  const MeterConfigurationTableBody = ({ state }) => {
    const timezone = state.meterData.timezone;
    const meterFirstCommunicatedTimestamp = state.firstTimestamp?.timestamp;
    const meterLastCommunicatedTimestamp = state.meterData.comms.lastHeardAt;

    //convert timestamp to date object based on timezone
    const formattedMeterFirstCommunicatedDate = meterFirstCommunicatedTimestamp
      ? formatDateTime(toZonedTime(new Date(meterFirstCommunicatedTimestamp * 1000), timezone))
      : 'N/A';

    const lastHeardInMinutes = getMinuteDifferenceBetweenTimeStamps(timezone, meterLastCommunicatedTimestamp);

    const processMeterLastCommunicated = () => {
      if (!meterLastCommunicatedTimestamp)
        return {
          wwLastCommunicatedColor: 'red',
          wwLastCommunicatedIcon: RiCloseCircleFill,
          wwLastCommunicatedTime: 'N/A',
        };

      const wwLastCommunicatedColor =
        lastHeardInMinutes <= 10 ? (lastHeardInMinutes <= 1 ? 'green' : 'yellow.500') : 'red';
      const wwLastCommunicatedIcon =
        lastHeardInMinutes <= 10
          ? lastHeardInMinutes <= 1
            ? AiFillCheckCircle
            : RiErrorWarningFill
          : RiCloseCircleFill;
      const getWWLastCommunicatedTime = () => {
        if (isNaN(lastHeardInMinutes)) return 'N/A';
        if (lastHeardInMinutes <= 1) {
          return '< 1m ago';
        } else if (lastHeardInMinutes < 60) {
          return `< ${lastHeardInMinutes}m ago`;
        } else {
          const differenceInHours = Math.ceil(lastHeardInMinutes / 60);
          if (differenceInHours < 24) {
            return `< ${differenceInHours}h ago`;
          } else {
            return `< ${Math.ceil(differenceInHours / 24)}d ago`;
          }
        }
      };
      return { wwLastCommunicatedColor, wwLastCommunicatedIcon, wwLastCommunicatedTime: getWWLastCommunicatedTime() };
    };

    const { wwLastCommunicatedColor, wwLastCommunicatedIcon, wwLastCommunicatedTime } = processMeterLastCommunicated();
    return (
      <tbody>
        <tr>
          <td>{state.meterData.model}</td>
          <MeterCommsStatus
            signalQualityDbm={state.meterData.comms.signalQualityDbm}
            commsType={state.meterData.comms.type}
            cellMode={cellMode}
            lastHeardInMinutes={lastHeardInMinutes}
          />
          <td>{formattedMeterFirstCommunicatedDate}</td>
          <td style={{ paddingTop: '8px' }}>
            <Flex direction="column" align="center">
              <Icon w={9} h={9} color={wwLastCommunicatedColor} as={wwLastCommunicatedIcon} />
              <Text fontWeight={600} color={wwLastCommunicatedColor}>
                {wwLastCommunicatedTime}
              </Text>
            </Flex>
          </td>
          <td>{state.meterData.comms.networkId}</td>
        </tr>
      </tbody>
    );
  };

  return (
    <>
      <Prompt when={hasUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />

      <ContentWrapper title={'Meter Configuration'}>
        {state.isLoaded ? (
          <div>
            <MeterSerialNumberForm
              serialNumber={state.serialNumber || id || ''}
              onFetchMeterData={handlePopulateForm}
            />

            {state.isUninitialised ? (
              <Alert variant="warning">
                <Alert.Heading>This device is not connected to Wattwatchers</Alert.Heading>
                <p>The device hasn't initiated a connected to the Wattwatchers system</p>

                <p>
                  Please ensure the device is powered up and allow a few minutes for it to connect before trying again
                </p>
                <hr />
                <div>
                  <Button
                    disabled={state.isLoading}
                    style={{ margin: '2rem 0', height: '50px', width: '200px', marginRight: '0.5rem' }}
                    size="lg"
                    variant="dark"
                    type="submit"
                    onClick={() => {
                      setState({
                        ...INITIAL_STATE,
                        serialNumber: state.serialNumber,
                      });
                    }}
                  >
                    Try again
                  </Button>
                </div>
              </Alert>
            ) : (
              state.isEditing && (
                <>
                  <h3>Meter Data</h3>
                  <Table style={{ width: '50%' }} striped bordered hover>
                    <thead>
                      <tr>
                        <th>Model</th>
                        <th>Signal Strength (dBm)</th>
                        <th>First Communicated</th>
                        <th>Last Communicated</th>
                        <th>Network ID</th>
                      </tr>
                    </thead>
                    <MeterConfigurationTableBody state={state} />
                  </Table>

                  <EditMeterForm
                    onSaveMeter={(meterData, siteCircuits) => {
                      setState({
                        ...state,
                        meterData,
                        siteCircuits,
                      });
                    }}
                    hasUnsavedChanges={hasUnsavedChanges}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    serialNumber={state.serialNumber}
                    meterData={state.meterData}
                    logData={state.logData || []}
                    siteId={state.siteId}
                    siteCircuits={state.siteCircuits}
                  />
                </>
              )
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
            <Spinner style={{ width: '50px', height: '50px' }} animation="border" variant="success" />
          </div>
        )}
      </ContentWrapper>
    </>
  );
}

const INITIAL_METER_SEARCH_STATE = {
  selectedMeterIndex: -1,
  isSearching: false,
  searchResults: [],
  isResultModalOpen: false,
  searchType: 'site',
};

const SEARCH_FILTER_OPTIONS = [
  { label: 'Site ID or Name', value: 'site' },
  { label: 'Meter Serial Number', value: 'meter' },
];

const SEARCH_FILTER_VALUE_TO_LABEL = {
  site: 'Site ID or Name',
  meter: 'Meter Serial Number',
};

// custom styling to match chakra-ui form styles
const customReactSelectStyles = {
  control: (provided: Record<any, any>, state: Record<any, any>) => ({
    ...provided,
    padding: 2,
    borderRadius: 4,
    boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none',
    borderColor: state.isFocused ? '#3182ce' : 'gray.200',
    '&:hover': {
      borderColor: state.isFocused ? '#3182ce' : 'gray.200',
    },
  }),
};

const WATTWATCHERS_MANUFACTURER_ID = 72;

function MeterSerialNumberForm({ onFetchMeterData, serialNumber }) {
  const [state, setState] = useState(INITIAL_METER_SEARCH_STATE);
  const inputRef = useRef<HTMLInputElement>();
  const closeButtonRef = useRef<HTMLDivElement>();

  useEffect(() => {
    // Display the selected meter to user
    if (inputRef?.current) inputRef.current.value = serialNumber;
    if (serialNumber) setState((prevState) => ({ ...prevState, searchType: 'meter' }));
  }, [serialNumber]);

  const handleSearch = React.useMemo(
    () =>
      debounceEvent(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.trim();
        if (!searchTerm) {
          setState((prevState) => ({ ...prevState, isSearching: false, searchResults: [], isResultModalOpen: false }));
          return null;
        }

        setState((prevState) => ({ ...prevState, isSearching: true, searchResults: [], isResultModalOpen: true }));
        try {
          const { jwtToken: token } = await getTokenAndEmailFromSession();
          const isSearchingSites = state.searchType === 'site';
          const searchResults = [];

          if (isSearchingSites) {
            const { data: sites } = await get('sites', `/site/sites/search/${searchTerm}`, token);
            // flatten the results
            sites.forEach((site) => {
              let hasWattwatchersMeter = false;
              site.meters.forEach((meter) => {
                if (meter.meta_data.manufacturer_id === WATTWATCHERS_MANUFACTURER_ID) {
                  hasWattwatchersMeter = true;
                  searchResults.push({
                    siteId: site.clipsal_solar_id,
                    siteName: site.site_name,
                    serialNumber: meter.site_identifier,
                  });
                }
              });

              // If no wattwatchers meter in the site display unavailable
              // Might be useful for users to locate issues
              if (!hasWattwatchersMeter) {
                searchResults.push({
                  siteId: site.clipsal_solar_id,
                  siteName: site.site_name,
                  serialNumber: null,
                });
              }
            });
          } else {
            const meters = await get('devices', `/device/meters/search?ww_device_id=${searchTerm}`, token);
            meters.map((meter) => {
              searchResults.push({
                siteId: meter.clipsal_solar_id,
                siteName: meter.site_name,
                serialNumber: meter.site_identifier,
              });
            });
          }

          setState((prevState) => ({ ...prevState, searchResults, isSearching: false }));
        } catch (e) {
          displayAPIErrorMessage(e);
          setState((prevState) => ({ ...prevState, isSearching: false }));
        }
      }, 600),
    [state.searchType]
  );

  useOutsideClick({
    ref: closeButtonRef,
    handler: () =>
      state.selectedMeterIndex === -1 && setState((prevState) => ({ ...prevState, isResultModalOpen: false })),
  });

  return (
    <Flex w={'100%'} align="center">
      <FormControl maxW={'500px'} my={3}>
        <FormLabel fontSize="14px">Search {SEARCH_FILTER_VALUE_TO_LABEL[state.searchType]}</FormLabel>
        <InputGroup size="md" position="relative" maxW={'500px'} ref={closeButtonRef}>
          <Input
            data-testid={'search-meter-input'}
            ref={inputRef}
            onFocus={() => setState((prevState) => ({ ...prevState, isResultModalOpen: !!state.searchResults.length }))}
            onChange={handleSearch}
            fontSize="14px"
            py={'20px'}
            placeholder={`Enter ${SEARCH_FILTER_VALUE_TO_LABEL[state.searchType]}`}
          />
          <InputRightElement width={state.isSearching || state.isResultModalOpen ? '4.5rem' : '0'} height="100%">
            {state.isSearching ? (
              <SpinnerChakra my="auto" color="gray.500" size="lg" />
            ) : (
              state.isResultModalOpen && (
                <IconButton
                  aria-label="Reset Search"
                  icon={<CloseIcon />}
                  onClick={() => {
                    if (inputRef?.current) inputRef.current.value = '';
                    setState((prevState) => ({ ...INITIAL_METER_SEARCH_STATE, searchType: prevState.searchType }));
                  }}
                />
              )
            )}
          </InputRightElement>
          {state.isResultModalOpen && (
            <TableContainer
              shadow=" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
              bg="white"
              borderRadius="0px 0px 10px 10px"
              position="absolute"
              top={43}
              zIndex={1}
              width="100%"
              maxW={'500px'}
            >
              {!state.searchResults.length && (
                <Text py={12} align="center">
                  {!state.isSearching ? 'No search results found. Please try again.' : 'Searching...'}
                </Text>
              )}

              {!!state.searchResults.length && (
                <ChakraTable variant="simple" size="lg" colorScheme="blue">
                  <Thead>
                    <Tr>
                      <Th border={'0px !important'} fontSize="12px">
                        Site ID
                      </Th>
                      <Th border={'0px !important'} fontSize="12px">
                        Site Name
                      </Th>
                      <Th border={'0px !important'} fontSize="12px">
                        Serial Number
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {state.searchResults.map((data, idx) => {
                      const hasWattwatchersMeter = !!data.serialNumber;

                      return (
                        <Tr
                          data-testid={`search-result-tr-${idx}`}
                          key={data.siteName + idx}
                          position="relative"
                          _hover={{
                            bg: hasWattwatchersMeter ? 'blue.50' : 'gray.50',
                            cursor: hasWattwatchersMeter ? 'pointer' : 'not-allowed',
                          }}
                          onClick={async () => {
                            if (!hasWattwatchersMeter) return;
                            const newSerialNumber = data.serialNumber.trim();

                            if (newSerialNumber === serialNumber) {
                              setState((prevState) => ({ ...prevState, isResultModalOpen: false }));
                              return;
                            }

                            setState((prevState) => ({ ...prevState, selectedMeterIndex: idx }));
                            try {
                              const { jwtToken: token } = await getTokenAndEmailFromSession();

                              let logs;
                              let meterData;
                              const siteCircuits = [];

                              const siteId = data.siteId || null;

                              if (siteId) {
                                const responses = await Promise.all([
                                  get('ww_meter_data', `/device/wattwatchers/devices/${newSerialNumber}`, token),
                                  get('circuits', `/site/sites/${siteId}/circuits`, token),
                                  get('ww_logs', `/device/wattwatchers/devices/${newSerialNumber}/logs`, token),
                                ]);
                                meterData = responses[0];
                                siteCircuits.push(...responses[1]);
                                logs = responses[2].data;
                              } else {
                                meterData = await get(
                                  'ww_meter_data',
                                  `/device/wattwatchers/devices/${newSerialNumber}`,
                                  token
                                );
                              }

                              onFetchMeterData(meterData, newSerialNumber, siteId, siteCircuits, logs);
                            } catch (e) {
                              displayAPIErrorMessage(e);
                            }
                            setState((prevState) => ({
                              ...prevState,
                              selectedMeterIndex: -1,
                              isResultModalOpen: false,
                            }));
                          }}
                        >
                          <Td border={'0px !important'}>{data.siteId || 'N/A'}</Td>
                          <Td border={'0px !important'} maxW="180px" isTruncated>
                            {data.siteName || 'N/A'}
                          </Td>
                          <Td border={'0px !important'}>{data.serialNumber || 'No Wattwatchers Meter on site'}</Td>
                          {state.selectedMeterIndex === idx && (
                            <SpinnerChakra position="absolute" bottom={5} right={8} zIndex={2} size="sm" />
                          )}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </ChakraTable>
              )}
            </TableContainer>
          )}
        </InputGroup>
      </FormControl>

      <FormControl maxW="200px" my={5} ml={8}>
        <FormLabel fontSize="14px">Search Filter</FormLabel>
        <Select
          styles={customReactSelectStyles}
          options={SEARCH_FILTER_OPTIONS}
          value={{ label: SEARCH_FILTER_VALUE_TO_LABEL[state.searchType], value: state.searchType }}
          onChange={(event) => {
            const searchType = event.value;
            if (inputRef?.current) inputRef.current.value = '';
            setState((prevState) => ({
              ...prevState,
              searchType,
              searchResults: prevState.searchType === searchType ? prevState.searchResults : [],
            }));
          }}
          placeholder="Select Search Filter..."
        />
      </FormControl>
    </Flex>
  );
}

const meterFormValidationSchema = yup.object().shape({
  meterName: yup.string().required(),
  timezone: yup.string().required(),
  channelConfig: yup.array().of(
    yup.object().shape({
      categoryId: yup.number().required('Required'),
      ctRating: yup.number().required('Required'),
      label: yup.string().required('Required'),
      polarity: yup.string().required('Required'),
      voltageReference: yup.string().required('Required'),
    })
  ),
  switchConfig: yup.array().of(
    yup.object().shape({
      categoryId: yup.number().required('Required'),
      contactorType: yup.string().required('Required'),
      label: yup.string().required('Required'),
      closedStateLabel: yup.string().required('Required'),
      openStateLabel: yup.string().required('Required'),
      state: yup.string().required('Required'),
    })
  ),
});

function deleteLabel(channel) {
  const clonedChannel = cloneDeep(channel);
  delete clonedChannel.categoryLabel;
  delete clonedChannel.pending;

  return clonedChannel;
}

function deleteSwitchCategory(switcheData) {
  const clonedSwitch = cloneDeep(switcheData);
  delete clonedSwitch.categoryId;
  delete clonedSwitch.categoryLabel;
  delete clonedSwitch.pending;

  return clonedSwitch;
}

function EditMeterForm(props) {
  const [isCollapsedOpen, setCollapseOpen] = useState(false);
  const [isMeterValuesPending, setIsMeterValuesPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef(null);

  function checkPendingValues(channels) {
    const channelsWithPendingValues = channels.find((channel) => channel.hasOwnProperty('pending'));
    setIsMeterValuesPending(!!channelsWithPendingValues);
  }

  async function updateMeterValuesFromAPI() {
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const meterData = await get('ww_meter_data', `/device/wattwatchers/devices/${props.serialNumber}`, token);
    checkPendingValues(meterData.channels);
    props.onSaveMeter(meterData, props.siteCircuits);
  }

  useEffect(() => {
    checkPendingValues(props.meterData.channels);
  }, []);

  useInterval(
    () => {
      updateMeterValuesFromAPI();
    },

    isMeterValuesPending ? 5000 : null
  );

  async function handleSubmit(values) {
    setIsLoading(true);
    const body = {
      id: props.serialNumber,
      label: values.meterName,
      channels: values.channelConfig.map(deleteLabel),
      phases: props.meterData.phases,
      timezone: values.timezone,
      switches: null,
    };

    if (values?.switchConfig?.length) {
      body.switches = values.switchConfig.map(deleteSwitchCategory);
    }

    if (body.switches === null) {
      delete body.switches;
    }

    try {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      let meterData;
      let circuitData = [...props.siteCircuits];

      if (props.siteId) {
        // need to update circuits label in our db if associated to site
        circuitData = circuitData.map((circuit) => {
          const wwCircuit = body.channels.find((channel) => channel.id === circuit.oem_circuit_id);
          if (wwCircuit) {
            const clonedCircuit = { ...circuit };
            clonedCircuit.circuit_name = wwCircuit.label;
            return clonedCircuit;
          }
          return circuit;
        });

        const responses = await Promise.all([
          post('circuits', `/site/sites/${props.siteId}/circuits`, circuitData, token),
          patch('ww_meter_configuration', `/device/wattwatchers/devices/${props.serialNumber}`, body, token),
        ]);

        meterData = responses[1];
      } else {
        meterData = await patch(
          'ww_meter_configuration',
          `/device/wattwatchers/devices/${props.serialNumber}`,
          body,
          token
        );
      }
      checkPendingValues(meterData.channels);
      props.onSaveMeter(meterData, circuitData);

      toast.success(`Successfully updated meter ${values.meterName} (${props.serialNumber})`);

      props.setHasUnsavedChanges(false);
    } catch (e) {
      displayAPIErrorMessage(e);
    }
    setIsLoading(false);
  }

  const initialState = {
    serialNumber: props.serialNumber,
    meterName: props.meterData.label,
    timezone: props.meterData.timezone,
    phases: props.meterData.phases,
    channelConfig: props.meterData.channels,
    switchConfig: props.meterData.switches,
  };

  useEffect(() => {
    function handleWindowUnload(e) {
      if (props.hasUnsavedChanges && !isEqual(initialState, ref.current.values)) {
        e.preventDefault();
        e.returnValue = '';
        return;
      }

      delete e['returnValue'];
    }

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [props.hasUnsavedChanges]);

  return (
    <Formik
      innerRef={ref}
      validateOnChange
      enableReinitialize
      validationSchema={meterFormValidationSchema}
      onSubmit={handleSubmit}
      initialValues={initialState}
    >
      {({ handleSubmit, values, touched, errors, setFieldValue, handleChange, isValid }) => {
        return (
          <Form
            onChange={() => props.setHasUnsavedChanges(true)}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();

              if (!isValid) {
                toast.error('Make sure all required form fields have been filled out.');
              }

              handleSubmit(e);
            }}
          >
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="meterName">
                <Form.Label>Meter Name</Form.Label>
                <Form.Control
                  data-testid="meter-name"
                  type="text"
                  placeholder="Enter device meter name"
                  name="meterName"
                  value={values.meterName}
                  onChange={handleChange}
                  isInvalid={touched.meterName && !!errors.meterName}
                  isValid={touched.meterName && !errors.meterName}
                />
                <Form.Control.Feedback type="invalid">Meter name is required</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="timezone">
                <Form.Label>Timezone</Form.Label>
                <Form.Control
                  data-testid="meter-timezone"
                  value={values.timezone}
                  as={'select'}
                  name={`timezone`}
                  isInvalid={touched.timezone && !!errors.timezone}
                  isValid={touched.timezone && !errors.timezone}
                  onChange={(e) => {
                    e.preventDefault();

                    setFieldValue(`timezone`, e.currentTarget.value);
                  }}
                >
                  <option value={''}>Select a timezone...</option>
                  {FORMATTED_TIMEZONES.map((timezone, configIndex) => (
                    <option value={timezone} key={`option-${configIndex}-${timezone}`}>
                      {timezone}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Timezone is required</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <MeterConfigurationChartContainer
              timezone={values.timezone}
              channels={values.channelConfig}
              serialNumber={props.serialNumber}
            />
            <Form.Row>
              <Col md={12}>
                <h3>CT Channel Configuration</h3>
                <FieldArray name="channelConfig" component={MeterConfigForm} />
              </Col>
            </Form.Row>
            {props.meterData.switches?.length && (
              <Form.Row>
                <Col md={12}>
                  <h3>Switch Channel Configuration</h3>
                  <FieldArray name="switchConfig" component={SwitchConfigForm} />
                </Col>
              </Form.Row>
            )}
            <Button
              data-testid="save-config-btn"
              style={{ margin: '2rem 0', height: '50px', width: '200px', marginRight: '0.5rem' }}
              size={'lg'}
              variant="success"
              type="submit"
              disabled={isLoading}
            >
              Save Configuration
              {isLoading && <SpinnerChakra emptyColor="gray.200" color="blue.500" size="sm" ml={2} />}
            </Button>
            <Form.Row>
              <Button
                data-testid="logs-toggle-btn"
                onClick={() => {
                  setCollapseOpen(!isCollapsedOpen);
                }}
                style={{ margin: '2rem 0.5rem 2rem 5px', height: '50px', width: '200px', marginRight: '0.5rem' }}
                size={'lg'}
                variant="secondary"
              >
                {!isCollapsedOpen ? 'Show logs' : 'Hide Logs'}
              </Button>
            </Form.Row>
            <Collapse in={isCollapsedOpen}>
              <Form.Row data-testid="logs-table">
                <Col md={12}>
                  <Heading mb={2} size={'xl'}>
                    Logs
                  </Heading>
                </Col>
                <Col md={12}>
                  <div style={{ paddingTop: '4px' }}>
                    <BootstrapTable
                      keyField="id"
                      data={props.logData}
                      columns={columns}
                      pagination={paginationFactory({})}
                    />
                  </div>
                </Col>
              </Form.Row>
            </Collapse>
          </Form>
        );
      }}
    </Formik>
  );
}

const METER_FIELD_CONFIG = [
  {
    label: 'Channel',
    placeholder: null,
    id: 'id',
    width: 2,
    isReadOnly: true,
    type: 'text',
  },
  {
    label: 'CT Type',
    id: 'categoryId',
    width: 2,
    placeholder: 'Select a CT Type...',
    options: [],
    isReadOnly: false,
    type: 'select',
    valueType: 'number',
  },
  {
    label: 'CT Rating (A)',
    id: 'ctRating',
    width: 2,
    placeholder: 'Select a CT rating...',
    isReadOnly: false,
    options: [
      { label: 60, value: 60 },
      { label: 120, value: 120 },
      { label: 200, value: 200 },
      { label: 400, value: 400 },
      { label: 600, value: 600 },
    ],
    type: 'select',
    valueType: 'number',
  },
  {
    label: 'Label',
    id: 'label',
    width: 3,
    placeholder: 'Enter channel label',
    isReadOnly: false,
    type: 'text',
  },
  {
    label: 'Polarity',
    id: 'polarity',
    width: 1,
    placeholder: 'Select a polarity...',
    options: [
      { value: 'normal', label: <PolarityNormalIcon h={7} /> },
      { value: 'reverse', label: <PolarityReverseIcon h={7} /> },
    ],
    isReadOnly: false,
    type: 'toggle-switch',
  },
  {
    label: 'Voltage Reference',
    id: 'voltageReference',
    width: 2,
    placeholder: 'Select a voltage reference...',
    options: [
      { value: 'P1', label: 'A' },
      { value: 'P2', label: 'B' },
      { value: 'P3', label: 'C' },
    ],
    isReadOnly: false,
    type: 'toggle-switch',
  },
];

export const MeterConfigForm = (props) => {
  const { form } = props;
  const [fieldConfig, setFieldConfig] = useState({ config: METER_FIELD_CONFIG, isLoaded: false });
  const { values } = form;

  useEffect(() => {
    async function fetchAPI() {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const categories = await get('ww_meter_categories', `/device/wattwatchers/channel_categories`, token);

      const config = METER_FIELD_CONFIG;

      config.find((item) => item.id === 'categoryId').options = categories.map((category) => ({
        label: category.label,
        value: category.id,
      }));

      setFieldConfig({
        isLoaded: true,
        config,
      });
    }

    if (!fieldConfig.isLoaded) {
      fetchAPI();
    }
  }, [fieldConfig.isLoaded]);

  if (!fieldConfig.isLoaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
        <Spinner style={{ width: '50px', height: '50px' }} animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {values.channelConfig.map((channelConfig, index) => (
        <Form.Row key={index}>{METER_FIELD_CONFIG.map(mapFieldConfig(form, 'channelConfig', index))}</Form.Row>
      ))}
    </div>
  );
};

const SWITCH_FIELD_CONFIG = [
  {
    label: 'Switch ID',
    placeholder: null,
    id: 'id',
    width: 2,
    isReadOnly: true,
    type: 'text',
  },
  {
    label: 'Switch Label',
    placeholder: 'Enter a label...',
    id: 'label',
    width: 2,
    isReadOnly: false,
    type: 'text',
  },
  {
    label: 'Switch Type',
    placeholder: 'Select a contactor type...',
    id: 'contactorType',
    width: 1,
    isReadOnly: false,
    type: 'select',
    options: [
      { label: 'NO', value: 'NO' },
      { label: 'NC', value: 'NC' },
    ],
  },
  {
    label: 'Closed State Label',
    placeholder: 'Select a closed state label...',
    id: 'closedStateLabel',
    width: 2,
    isReadOnly: false,
    type: 'select',
    options: [
      { label: 'On', value: 'on' },
      { label: 'Off', value: 'off' },
    ],
  },
  {
    label: 'Open State Label',
    placeholder: 'Select an open state label...',
    id: 'openStateLabel',
    width: 1,
    isReadOnly: false,
    type: 'select',
    options: [
      { label: 'On', value: 'on' },
      { label: 'Off', value: 'off' },
    ],
  },
  {
    label: 'State',
    placeholder: 'Select a state...',
    id: 'state',
    width: 1,
    isReadOnly: false,
    type: 'switch',
  },
];

export const SwitchConfigForm = (props) => {
  const { form } = props;
  const { values } = form;

  return (
    <div style={{ width: '100%' }}>
      {values.switchConfig?.map((switchConfig, index) => (
        <Form.Row key={index}>{SWITCH_FIELD_CONFIG.map(mapFieldConfig(form, 'switchConfig', index))}</Form.Row>
      ))}
    </div>
  );
};

/**
 * Uses some form metadata to wrap a function which constructs a new field, based on the provided configuration.
 * Really only useful for Formik `FieldArray`s at the moment.
 *
 * Intended to be passed to `Array.map` as a higher-order function:
 *
 * @note: refactor `control` assignment to an map if the conditional logic ends up more complex.
 *
 * @example `myArray.map(mapFieldConfig(form, key, index))`
 *
 * @param {object} formikForm The Formik form being used in the returned function's context.
 * @param {string} formKey The form key which this field resides in.
 * @param {number} rowIndex The form row index.
 * @returns {function({label: *, placeholder: *, id: *, width: *, isReadOnly: *, type: *, options: *}, *)}
 */
function mapFieldConfig(formikForm, formKey, rowIndex) {
  const { values, errors, touched, setFieldValue } = formikForm;

  return ({ label, placeholder, id, width, isReadOnly, type, options, valueType }, fieldIndex) => {
    const error = getIn(errors, `${formKey}[${rowIndex}].${id}`);
    const touch = getIn(touched, `${formKey}[${rowIndex}].${id}`);
    const value = getIn(values, `${formKey}[${rowIndex}].${id}`);
    const pending = getIn(values, `${formKey}[${rowIndex}].pending.${id}`);

    async function handleUpdateStateSwitch(newValues) {
      const body = {
        id: newValues.serialNumber,
        label: newValues.meterName,
        channels: newValues.channelConfig.map(deleteLabel),
        phases: newValues.phases,
        timezone: newValues.timezone,
        switches: null,
      };

      if (newValues?.switchConfig?.length) {
        body.switches = newValues.switchConfig.map(deleteLabel);
      }

      if (body.switches === null) {
        delete body.switches;
      }

      try {
        const { jwtToken: token } = await getTokenAndEmailFromSession();
        await patch('ww_meter_configuration', `/device/wattwatchers/devices/${newValues.serialNumber}`, body, token);

        // @TODO: update form value to `pending`, setup interval for state update (extract this function to a component)
        //const stateChanged = res.switches[updatedSwitchIndex]?.pending?.state;

        toast.success(`Successfully updated switch ${newValues.meterName} (${newValues.serialNumber})`);
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    let control = (
      <>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          data-testid={`${rowIndex} - ${label}`}
          as={Field}
          readOnly={isReadOnly}
          type={type}
          placeholder={placeholder}
          name={`${formKey}.${rowIndex}.${id}`}
          isInvalid={touch && !!error}
          isValid={touch && !error}
        />
      </>
    );

    if (type === 'select') {
      control = (
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            data-testid={`${rowIndex} - ${label}`}
            value={pending || value}
            disabled={!!pending}
            key={`${formKey}-config-field-${fieldIndex}`}
            as={'select'}
            name={`${formKey}.${rowIndex}.${id}`}
            isInvalid={touch && !!error}
            isValid={touch && !error}
            onChange={(e) => {
              e.preventDefault();
              let value: string | number = e.currentTarget.value;

              if (valueType === 'number') {
                value = Number(e.currentTarget.value);
              }

              // @TODO: only convert to number for number fields
              setFieldValue(`${formKey}.${rowIndex}.${id}`, value);
            }}
          >
            <option value={''}>{placeholder}</option>
            {options.map((config, configIndex) => (
              <option value={config.value} key={`option-${configIndex}-${rowIndex}`}>
                {config.label}
              </option>
            ))}
          </Form.Control>
          {pending && (
            <Flex justify="flex-start" align="center" py={1}>
              <SpinnerChakra emptyColor="gray.200" color="blue.500" size="md" mr={2} />

              <Text fontWeight="normal" color="gray.400" fontSize="14px">
                Updating...
              </Text>
            </Flex>
          )}
        </>
      );
    } else if (type === 'toggle-switch') {
      control = (
        <div>
          <label htmlFor={id}>{label}</label>
          <MultiToggleSwitch
            data-testid={`${rowIndex} - ${label}`}
            disabled={pending}
            switchOptions={options}
            fontSize="14px"
            enableBottomLabel
            setValueAsBottomLabel={false}
            value={pending || value}
            onChange={(val: string) => {
              const value: string | number = valueType === 'number' ? Number(val) : val;
              setFieldValue(`${formKey}.${rowIndex}.${id}`, value);
            }}
          />
        </div>
      );
    } else if (type === 'switch') {
      control = (
        <label
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          htmlFor={id}
        >
          <div>{label}</div>
          <Switch
            data-testid={`${rowIndex} - ${label}`}
            onChange={async (e) => {
              const openOrClosed = e.target.checked ? 'open' : 'closed';
              setFieldValue(`${formKey}.${rowIndex}.${id}`, openOrClosed);

              // This is yuck but I can't figure out a nicer way to solve, don't judge
              const currentValuesClone = cloneDeep(values);
              currentValuesClone[formKey][rowIndex][id] = openOrClosed;

              if (formikForm.isValid) {
                await handleUpdateStateSwitch(currentValuesClone /* , rowIndex */);
              }
            }}
            isChecked={value === 'open'}
            size="lg"
          />
        </label>
      );
    }

    return (
      <Form.Group key={`${formKey}-config-field-${fieldIndex}`} as={Col} md={width} controlId={id}>
        {control}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    );
  };
}
